import { useEffect, useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { shuffle, chunk } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faRefresh, faHome } from '@fortawesome/free-solid-svg-icons'
import { NavBar } from '../Navigation/NavBar';
import { useNavigate } from 'react-router-dom';


/*
*
*
an array of items each representing a piece of the jigsaw puzzle image
*
*
*/
const pieces = [
  { id: 'a1', url: 'https://www.perl-developer.com/pieces/a1.png' },
  { id: 'a2', url: 'https://www.perl-developer.com/pieces/a2.png' },
  { id: 'a3', url: 'https://www.perl-developer.com/pieces/a3.png' },
  { id: 'a4', url: 'https://www.perl-developer.com/pieces/a4.png' },
  { id: 'a5', url: 'https://www.perl-developer.com/pieces/a5.png' },
  { id: 'b1', url: 'https://www.perl-developer.com/pieces/b1.png' },
  { id: 'b2', url: 'https://www.perl-developer.com/pieces/b2.png' },
  { id: 'b3', url: 'https://www.perl-developer.com/pieces/b3.png' },
  { id: 'b4', url: 'https://www.perl-developer.com/pieces/b4.png' },
  { id: 'b5', url: 'https://www.perl-developer.com/pieces/b5.png' },
  { id: 'c1', url: 'https://www.perl-developer.com/pieces/c1.png' },
  { id: 'c2', url: 'https://www.perl-developer.com/pieces/c2.png' },
  { id: 'c3', url: 'https://www.perl-developer.com/pieces/c3.png' },
  { id: 'c4', url: 'https://www.perl-developer.com/pieces/c4.png' },
  { id: 'c5', url: 'https://www.perl-developer.com/pieces/c5.png' },
  { id: 'd1', url: 'https://www.perl-developer.com/pieces/d1.png' },
  { id: 'd2', url: 'https://www.perl-developer.com/pieces/d2.png' },
  { id: 'd3', url: 'https://www.perl-developer.com/pieces/d3.png' },
  { id: 'd4', url: 'https://www.perl-developer.com/pieces/d4.png' },
  { id: 'd5', url: 'https://www.perl-developer.com/pieces/d5.png' },
  { id: 'e1', url: 'https://www.perl-developer.com/pieces/e1.png' },
  { id: 'e2', url: 'https://www.perl-developer.com/pieces/e2.png' },
  { id: 'e3', url: 'https://www.perl-developer.com/pieces/e3.png' },
  { id: 'e4', url: 'https://www.perl-developer.com/pieces/e4.png' },
  { id: 'e5', url: 'https://www.perl-developer.com/pieces/e5.png' },
];

/*
*
*
the correct order of images represented by a multi-dimensional array of their IDs 
*
*
*/
const correctConfig = [
  ['a1', 'a2', 'a3', 'a4', 'a5'],
  ['b1', 'b2', 'b3', 'b4', 'b5'],
  ['c1', 'c2', 'c3', 'c4', 'c5'],
  ['d1', 'd2', 'd3', 'd4', 'd5'],
  ['e1', 'e2', 'e3', 'e4', 'e5'],
];

/*
*
*
a component displaying a piece of the puzzle image, that can be dragged and placed
on the board
*
*
*/
const Piece = ({ id, url, index }) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshot) => (
        <img
          src={url}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`piece ${snapshot.isDragging && 'dragging'}`}
        />
      )}
    </Draggable>
  );
};

/*
*
*
Main component
*
*
*/
function App() {

  let savedPositions = localStorage.getItem('positions');
  const savedMoves = +localStorage.getItem('moves');

  console.log('savedPositions', savedPositions);
  try {
    savedPositions = JSON.parse(savedPositions);
  } catch (e) {
    savedPositions = false;
  }

  console.log('savedPositions', savedPositions);


  let navigate = useNavigate();
  const initial = chunk(shuffle(pieces.map((p) => p.id)), 5);
  const [positions, updatePositions] = useState(savedPositions || initial);
  const [moves, setMoves] = useState(savedMoves || 0);


  const saveGameState = () => {
    localStorage.setItem('positions', JSON.stringify(positions));
    localStorage.setItem('moves', moves);
  };

  /*
  *
  *
  before navigating away, we save the game state 
  *
  *
  */
  useEffect(() => {
    return saveGameState
  }, [moves])

  /*
  *
  *
  square areas where puzzle pieces can be dropped 
  *
  *
  */
  function Slot({ row, col }) {
    const id = `slot-${row}-${col}`;
    const placedPieceId = positions[row][col];
    const placedPiece = pieces.find((p) => p.id === placedPieceId);

    return (
      <td>
        <Droppable droppableId={id}>
          {(provided, snapshot) => (
            <div
              className='slot'
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                background: snapshot.isDraggingOver ? '#DB5461' : 'none',
              }}
            >
              {placedPiece && (
                <Piece
                  key={placedPiece.id}
                  id={placedPiece.id}
                  url={placedPiece.url}
                  index={0}
                />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </td>
    );
  }

  const handleSwitch = (result) => {
    const clonedPositions = [...positions];
    const [_, ...sourcePos] = result.source.droppableId.split('-');
    const [__, ...destinationPos] = result.destination.droppableId.split('-');

    /*
    *
    *
    if the piece is dragged and put to where it was originally, do nothing
    *
    */
    if (sourcePos.join('') === destinationPos.join('')) {
      return;
    }

    const sourceValue = clonedPositions[sourcePos[0]][sourcePos[1]];
    const destinationValue =
      clonedPositions[destinationPos[0]][destinationPos[1]];

    clonedPositions[destinationPos[0]][destinationPos[1]] = sourceValue;
    clonedPositions[sourcePos[0]][sourcePos[1]] = destinationValue;

    updatePositions(clonedPositions);
    setMoves(m => m + 1);

    if (isGameCompleted()) {
      alert(`Done! you did it in ${moves} moves!`);
      updatePositions(initial);
      setMoves(0);
    }
  };

  /*
  *
  *
  chech if the game is completed correctly
  *
  *
  */
  const isGameCompleted = () => {
    return positions.flat().join('') === correctConfig.flat().join('');
  };

  /*
  *
  *
  render ui
  *
  *
  */
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <NavBar
        leftComponent={
          <button onClick={() => navigate('/splash')}>
            <FontAwesomeIcon icon={faHome} style={{ marginRight: 5 }} /> Back to home
          </button>
        }
        rightComponent={
          <>
            <button onClick={() => {
              updatePositions(initial);
              setMoves(0);
            }}>
              <FontAwesomeIcon icon={faRefresh} />
            </button>
            <button onClick={() => navigate('/about')}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </button>
          </>
        }

      />
      <h1>number of moves: {moves}</h1>
      <DragDropContext onDragEnd={handleSwitch}>
        <div className='frame'>
          <table className='board'>
            <tbody>
              <tr>
                <Slot row={0} col={0} />
                <Slot row={0} col={1} />
                <Slot row={0} col={2} />
                <Slot row={0} col={3} />
                <Slot row={0} col={4} />
              </tr>
              <tr>
                <Slot row={1} col={0} />
                <Slot row={1} col={1} />
                <Slot row={1} col={2} />
                <Slot row={1} col={3} />
                <Slot row={1} col={4} />
              </tr>
              <tr>
                <Slot row={2} col={0} />
                <Slot row={2} col={1} />
                <Slot row={2} col={2} />
                <Slot row={2} col={3} />
                <Slot row={2} col={4} />
              </tr>
              <tr>
                <Slot row={3} col={0} />
                <Slot row={3} col={1} />
                <Slot row={3} col={2} />
                <Slot row={3} col={3} />
                <Slot row={3} col={4} />
              </tr>
              <tr>
                <Slot row={4} col={0} />
                <Slot row={4} col={1} />
                <Slot row={4} col={2} />
                <Slot row={4} col={3} />
                <Slot row={4} col={4} />
              </tr>
            </tbody>
          </table>
        </div>
      </DragDropContext>
    </div>
  );
}

export default App;